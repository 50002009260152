import P from 'prop-types';
import * as Styled from './styles';
const LinesSchedulePane = ({ children, active }) => {

    return (
        <Styled.Container active={active}>
            {children}
        </Styled.Container>
    );
};
LinesSchedulePane.propTypes = {
    children: P.arrayOf(P.node).isRequired,
    active: P.bool
};
export default LinesSchedulePane;