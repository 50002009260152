import P from "prop-types";
import * as Styled from "./styles";
import { useTheme } from "styled-components";
import { useState } from "react";

const BannerSubMenu = ({ anchors, closeFn, closeMainMenu }) => {
  const theme = useTheme();
  
  return (
    <Styled.Container bgColor={theme.colors.white}>
      <Styled.CloseButtonContainer>
        <Styled.CloseButtonButton onClick={closeFn}/>
      </Styled.CloseButtonContainer>
      <Styled.NavbarItensWrapper>
        {anchors.map((anchor) => (
          <Styled.NavbarItem key={anchor.text}>
            {anchor.href === "" || anchor.href === undefined ? 
            <Styled.NavbarItemButton onClick={() => {
              closeMainMenu();
              closeFn();
              anchor.clickFn();
              }}>
              {anchor.text}
            </Styled.NavbarItemButton>          
            :
            <Styled.NavbarItemButton as={'a'} 
            target={'_blank'} 
            href={anchor.href} 
            onClick={() => {
              closeMainMenu();
              closeFn();
              }
              }>

              {anchor.text}
            </Styled.NavbarItemButton>
            }
          </Styled.NavbarItem>
        ))}
      </Styled.NavbarItensWrapper>
    </Styled.Container>
  );
};
BannerSubMenu.propTypes = {
  anchors: P.arrayOf(P.shape({ text: P.string, clickFn: P.func })),
  closeFn: P.func,
  closeMainMenu: P.func
};
export default BannerSubMenu;
