import P from 'prop-types';
import * as Styled from './styles';
const TransparentContainer = ({ children, bgColor, alpha = 0.5 }) => {
    return (
        <Styled.Container bgColor={bgColor} alpha={alpha}>
            {children}
        </Styled.Container>
    );};
TransparentContainer.propTypes = {
    children: P.node.isRequired,
    bgColor: P.string,
    alpha: P.number
};
export default TransparentContainer;