import { createContext, useState } from 'react';

export const MaisInformacoesModalContext = createContext();

export const MaisInformacoesModalContextProvider = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({});
    const value = { open, setOpen, data, setData};

    return <MaisInformacoesModalContext.Provider value={value}>{children}</MaisInformacoesModalContext.Provider>
}