import { useContext } from 'react';
import P from 'prop-types';
import * as Styled from './styles';

import MaisInformacoesModal from '../MaisInformacoesModal';
import BemVindoModal from '../BemVindoModal';
import FolheteriaModal from '../FolheteriaModal';
import AcessoSiteModal from '../AcessoSiteModal';
import ConcessionarioModal from '../ConcessionarioModal';
import AgendaModal from '../AgendaModal';
import PDFModal from '../PDFModal';
import VideoModal from '../VideoModal';
import VideoCarrosselModal from '../VideoCarrosselModal';

import { MaisInformacoesModalContext } from '../../context/MaisInformacoesModalContext';
import { BemVindoModalContext } from '../../context/BemVindoModalContext';
import { FolheteriaModalContext } from '../../context/FolheteriaModalContext';
import { AcessoSiteModalContext } from '../../context/AcessoSiteModalContext';
import { ConcessionarioModalContext } from '../../context/ConcessionarioModalContext';
import { AgendaModalContext } from '../../context/AgendaModalContext';

import { DiagonalTabContext } from '../../context/DiagonalTabContext';
import { PDFModalContext } from '../../context/PDFModalContext';
import { VideoModalContext } from '../../context/VideoModalContext';
import { VideoCarrosselModalContext } from '../../context/VideoCarrosselModalContext';
import { BannerModalContext } from '../../context/BannerModalContext';
import { LinkExternoModalContext } from '../../context/LinkExternoModalContext';



import DiagonalTabsContainer from '../DiagonalTabsContainer';
import BannerModal from '../BannerModal';
import LinkExternoModal from '../LinkExternoModal';

const ComponentsWrapper = () => {

    const { open } = useContext(DiagonalTabContext);
    
    const { open:openMaisInfo, setOpen } = useContext(MaisInformacoesModalContext);
    const { open:openBemVindo } = useContext(BemVindoModalContext);
    const { open:openFolheteria } = useContext(FolheteriaModalContext);
    const { open:openAcessoSite } = useContext(AcessoSiteModalContext);
    const { open:openConcessionario } = useContext(ConcessionarioModalContext);
    const { open:openAgenda } = useContext(AgendaModalContext);
    const { open:openPDF } = useContext(PDFModalContext);
    const { open:openVideo } = useContext(VideoModalContext);
    const { open:openVideoCarrossel } = useContext(VideoCarrosselModalContext);
    const { open:openBannerModal } = useContext(BannerModalContext);
    const { open:openLinkExterno } = useContext(LinkExternoModalContext);

    
    return <>
        {open && <DiagonalTabsContainer />}
        {openBemVindo && <BemVindoModal />}
        {openMaisInfo && <MaisInformacoesModal />}
        {openFolheteria && <FolheteriaModal />}
        {openAcessoSite && <AcessoSiteModal />}
        {openConcessionario && <ConcessionarioModal />}
        {openAgenda && <AgendaModal />}
        {openPDF && <PDFModal />}
        {openVideo && <VideoModal />}
        {openVideoCarrossel && <VideoCarrosselModal />}
        {openBannerModal && <BannerModal />}
        {openLinkExterno && <LinkExternoModal />}

    </>;
};
ComponentsWrapper.propTypes = {};
export default ComponentsWrapper;