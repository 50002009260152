import { useState, useEffect } from 'react';
import P from 'prop-types';
import * as Styled from './styles';

const CarrosselPure = ({ data, time = 2500, nextTrigger, prevTrigger, drillIndex }) => {
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex)
    }

    useEffect(() => {
        setIndex(drillIndex)
    },[drillIndex])

    return (
        <Styled.Container>
            {data.map((item, i) => <Styled.Item key={i} index={i} show={index === i}>{item}</Styled.Item>)}
        </Styled.Container>
    );
};
CarrosselPure.propTypes = {
    data: P.arrayOf(P.node).isRequired,
    time: P.number,
    nextTrigger: P.bool,
    prevTrigger: P.bool,
    drillIndex: P.number
};
export default CarrosselPure;