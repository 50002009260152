import { forwardRef } from 'react';
import P from 'prop-types';
import * as Styled from './styles';
const NativeVideo = forwardRef(({ videoUrl }, ref) => {
    return (
        <Styled.Container ref={ref} autoplay={false} muted>
            <Styled.Source src={videoUrl}/>
        </Styled.Container>
    );
})
NativeVideo.propTypes = {
    videoUrl: P.string,
};
export default NativeVideo;