import styled, { css } from 'styled-components';
import { Container as TransparentContainer } from '../../components/TransparentContainer/styles';
export const Container = styled.div`
    ${({ theme }) => css`
        
    `}
`;
export const Content = styled.div`
    ${({ theme }) => css`
        /* padding: ${theme.spacings.gutter}px; */
        position: relative;
        display: flex;
        width: 100%;
        border-radius: 10px;
        border: 1px solid rgba(0,0,0,0.1);
        
    `}
`;
export const Block = styled.div`
    ${({ theme, flexBasis }) => css`
        /* padding: ${theme.spacings.gutter}px; */
        display: flex;
        flex-basis: ${flexBasis};
        ${TransparentContainer}{
            border-radius: ${theme.rounds.small}; 
            padding: ${theme.spacings.gutter * 1.5}px ${theme.spacings.gutter}px;
            flex-grow: 1;
        }
    `}
`;
export const CloseButton = styled.button`
    ${({ theme }) => css`
        position: absolute;
        top: 10px;
        right: 10px;
        background: transparent;
        border: 1px solid transparent;
        margin: 0;
    `}
`;

export const MainHeading = styled.h1`
    ${({ theme }) => css`
        color: ${theme.colors.mainColor};
        font-weight: 700;
        font-size: 24px;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        margin-bottom: 30px;
    `}
`;

export const SecondaryHeading = styled.h4`
    ${({ theme }) => css`
        color: ${theme.colors.gray};
        font-size: 16px;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 1.5px;
        margin: 0;
    `}
`;
export const Paragraph = styled.p`
    ${({ theme }) => css`
        color: ${theme.colors.mainGray};
        font-weight: 300;
        font-size: 13px;
        margin-bottom: 30px;
        letter-spacing: 1.5px;
    `}
`;

export const List = styled.ul`
    ${({ theme }) => css`
        margin: 0;
    `}
`;
export const ListItem = styled.li`
    ${({ theme }) => css`
        color: ${theme.colors.mainGray};
        font-weight: 300;
        font-size: 13px;
        margin-bottom: 30px;
        letter-spacing: 1.5px;
    `}
`;

export const ButtonsContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        margin: auto;
        justify-content: center;
        gap: ${ theme.spacings.gutter }px;
        /* padding: ${ theme.spacings.gutter }px 0 0 0; */
        padding: 0;
        align-items: center;
        margin-top:${theme.spacings.gutter}px;
    `}
`;
export const Button = styled.a.attrs({ target: '_blank' })`
    ${({ theme }) => css`
        font-family: ${theme.fonts.mainFont};
        text-transform: uppercase;
        text-decoration: none;
        color: ${theme.colors.white};
        background-color: ${theme.colors.mainColor};
        font-weight: bold;
        font-size: 10px;
        padding: 12px 30px;
        letter-spacing: 2.5px;
        border: 1px solid ${theme.colors.mainColor};
        box-shadow: 2px 1px 3px rgba(159, 159, 159, 0.7);
        :hover {
            color:${theme.colors.white};
            background-color: ${theme.colors.mainColor};
            border: 1px solid ${theme.colors.mainColor};
        }
    `}
`;