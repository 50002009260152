import P from 'prop-types';
import * as Styled from './styles';
import ModalFixedSize from '../../components/ModalFixedSize';
import bannerJD from '../../assets/hotsite_banner_02.jpg';
import iconClose from '../../assets/icon-close-2.png';
import TransparentContainer from '../../components/TransparentContainer';

import { useContext, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { BannerModalContext } from '../../context/BannerModalContext';

const BannerModal = () => {
    const theme = useTheme();
    const { open, setOpen } = useContext(BannerModalContext);

    useEffect(() => {
        const { title } = document;
        document.title = title + '- Acesse o site';
        window.windowRegisterCounter('clique_fide');
        return () => {
            document.title = title;
        }
    },[]);

    return ( <Styled.Container>
                <ModalFixedSize size={'lg'} show={open} onHideFn={() => setOpen(false)} >
                    <Styled.Content>
                    <Styled.CloseButton onClick={() => setOpen(false)}><img src={iconClose} /></Styled.CloseButton>
                        <Styled.Block flexBasis={'100%'}>
                            <Styled.Anchor href="https://johndeere.seedz.ag/">
                                <Styled.Banner src={bannerJD}/>
                            </Styled.Anchor>
                        </Styled.Block>
                    </Styled.Content>
                </ModalFixedSize>
            </Styled.Container>
    );
};
BannerModal.propTypes = {};
export default BannerModal;