import P from 'prop-types';
import * as Styled from './styles';
import ModalFixedSize from '../../components/ModalFixedSize';
import { useContext, useEffect } from 'react';
import { useTheme } from 'styled-components';

import iconClose from '../../assets/icon-close-2.png';
import TransparentContainer from '../../components/TransparentContainer';
import { MaisInformacoesModalContext } from '../../context/MaisInformacoesModalContext';
import { FolheteriaModalContext } from '../../context/FolheteriaModalContext';
import { AcessoSiteModalContext } from '../../context/AcessoSiteModalContext';
import { DiagonalTabContext } from '../../context/DiagonalTabContext';

const MaisInformacoesModal = () => {
    const theme = useTheme();
    const { open, setOpen, data } = useContext(MaisInformacoesModalContext);
    const { setOpen:setOpenAcessoSite } = useContext(AcessoSiteModalContext);
    const { setOpen:setOpenFolheteria, setPdf } = useContext(FolheteriaModalContext);

    const { name, folheriaData, moreInfoData } = useContext(DiagonalTabContext);

    useEffect(() => {
        const { title } = document;
        document.title = title + ' - ' + data.name_file;
        return () => {
            document.title = title;
        }
    },[]);

    const renderTitle = () => {
        return (
            <>
            <Styled.SecondaryHeading>{data.category}</Styled.SecondaryHeading>
            <Styled.MainHeading>{data.name}</Styled.MainHeading>
            </>
        )
    } 

    const setPdfAndOpenFolheria = () => {
        setOpenFolheteria(true);
        setPdf(`${process.env.REACT_APP_PHOTOS_MACHINE_DIR}${data.file}`)
    }

    const openAcessoSite = () => {
        setOpen(false)
        setOpenAcessoSite(true)
    }

    return ( <Styled.Container>
                <ModalFixedSize size={'lg'} show={open} onHideFn={() => setOpen(false)} >
                    <Styled.Content>
                        <Styled.CloseButton onClick={() => setOpen(false)}><img src={iconClose} /></Styled.CloseButton>
                        <Styled.Block flexBasis={'100%'}>
                            <TransparentContainer bgColor={theme.colors.white} alpha={1}>
                                {renderTitle()}
                                <Styled.List>
                                    {data.texto.map((p, i) => <Styled.ListItem key={`${p}.${i}`}>{p.text}</Styled.ListItem>)}
                                </Styled.List>

                                <Styled.ButtonsContainer>
                                    <Styled.Button as="button" onClick={setPdfAndOpenFolheria}>Folheteria</Styled.Button>
                                    <Styled.Button href={data.link_1} >Acesse nosso site</Styled.Button>
                                </Styled.ButtonsContainer>

                            </TransparentContainer>
                        </Styled.Block>
                    </Styled.Content>
                </ModalFixedSize>
            </Styled.Container>
    );
};
MaisInformacoesModal.propTypes = {};
export default MaisInformacoesModal;