import P from 'prop-types';
import * as Styled from './styles';
import BannerMenu from '../../components/BannerMenu';

import { useContext, useState } from 'react';

import { ConteudosBonusModalContext } from '../../context/ConteudosBonusModalContext';
import { ConcessionarioModalContext } from '../../context/ConcessionarioModalContext';
import { AgendaModalContext } from '../../context/AgendaModalContext';
import { DiagonalTabContext } from '../../context/DiagonalTabContext';
import { AcessoSiteModalContext } from '../../context/AcessoSiteModalContext';
import { BannerModalContext } from '../../context/BannerModalContext';
import { DataContext } from '../../context/DataContext';
import { LinkExternoModalContext } from '../../context/LinkExternoModalContext';

const MainMenu = () => {

    const { machine } = useContext(DataContext);

    const { setOpen, addData, clearAndAddData } = useContext(DiagonalTabContext);
    const { setOpen:setOpenConcessionario } = useContext(ConcessionarioModalContext);
    const { setOpen:setOpenAgenda } = useContext(AgendaModalContext);
    const { setOpen:setOpenAcessoSite } = useContext(AcessoSiteModalContext);
    const { setOpen:setOpenBanner } = useContext(BannerModalContext);
    const { setOpen:setOpenLinkExterno, setData:setDataLinkExterno } = useContext(LinkExternoModalContext);
    

    const openDialog = (machine) => {
        setOpen(true);
        clearAndAddData(machine);
    }

    const openModal = (fn) => {
      setOpenConcessionario(false);
      setOpenAgenda(false);
      setOpenAcessoSite(false);
      setOpenBanner(false);
      fn(true);
    }

    const renderMachineLinks = (machines) => {
      return machines.map((machine) => ({
        text: `${machine.name_file}`,
        // clickFn: () => openDialog(`${machine.name_file}`, machine, machine)
        // clickFn: () => openDialog(machine)
        clickFn: () => {
          window.Navigation(machine.reference);
        }
      }))
    }

    const itensMenu =  [
        {
            text: 'Equipamentos',
            target: 'equipamentos',
            anchors: renderMachineLinks(machine)
        },
        {
          text: 'Compra e Financiamento',
          target: 'compra-financiamento',
          anchors: [
            {
              text: 'Banco',
              clickFn:() => {
                setOpenLinkExterno(true);
                setDataLinkExterno({
                  text: 'Banco',
                  href: 'https://www.deere.com.br/pt/financiamento/banco-john-deere/'
                }) 
              }
            },
            {
              text: 'Consórcio',
              clickFn:() => {
                setOpenLinkExterno(true);
                setDataLinkExterno({
                  text: 'Banco',
                  href: 'https://www.consorciojohndeere.com.br/'
                }) 
              }
            }
          ],
          // openModalFn: () => openModal(setOpenConcessionario)
        },
        {
          text: 'Fale com Concessionário',
          target: 'fale-concessionario',
          anchors: [],
          openModalFn: () => openModal(setOpenConcessionario)
        },
        {
          text: 'John Deere Fidelidade',
          target: 'jd-fidelidade',
          anchors: [],
          openModalFn: () => openModal(setOpenBanner)
        },
        {
          text: 'Conteúdo ao vivo',
          target: 'ao-vivo',
          anchors: [],
          openModalFn: () => {window.OpenLive(); window.Navigation("live");}
        },
        {
          text: 'Confira a Programação',
          target: 'programacao',
          anchors: [],
          openModalFn: () => openModal(setOpenAgenda)
        },
    ];

    const closeAllAndOpen = (openFn) => {
        // escrever fechamentos
        // setOpenPalcoPrincipalModal(false);
        // setOpenDiagnosticoModal(false);
        // setOpenConteudosBonusModal(false)
        openFn(true);
    }

    return (
        <Styled.Container>
            {machine !== undefined && <BannerMenu itensMenu={itensMenu} />}
        </Styled.Container>
    );
};
MainMenu.propTypes = {};
export default MainMenu;