import P from 'prop-types';
import * as Styled from './styles';
import { useContext } from 'react';
import DiagonalTab from '../../components/DiagonalTab';

import { DiagonalTabContext } from '../../context/DiagonalTabContext';
import { MaisInformacoesModalContext } from '../../context/MaisInformacoesModalContext';

const DiagonalTabsContainer = () => {
    const { data } = useContext(DiagonalTabContext);
    const { setOpen, setData } = useContext(MaisInformacoesModalContext);

    const openInfo = (machine) => {
        window.windowRegisterCounter("maq_" + machine.reference);
        setData(machine);
        setOpen(true);
    }

    return (
        <Styled.Container>
            {(data.length) > 0 && data.map((machine) => <DiagonalTab key={machine.reference} name={machine.name_file} openInfoFn={() => openInfo(machine)} />)}
        </Styled.Container>
    );
};
DiagonalTabsContainer.propTypes = {};
export default DiagonalTabsContainer;