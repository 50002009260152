import axios from 'axios';

import { useEffect, useState, createContext } from 'react';

export const DataContext = createContext();

export const DataContextProvider = ({ children }) => {
    const [data, setData] = useState({
        lecture:[],
        machine:[],
        speaker:[]
    }); 

    const [contatos, setContatos] = useState({});

    const value = {
        ...data,
        contatos
    };

    // useEffect(() => {
    //     console.log(data);
    // },[data]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_JSON_URL_HOST)
            .then((res) =>  setData(res.data) )
                .catch(console.error)
    },[]);

    useEffect(() => {
        axios.get('data/contatos.json')
            .then((res) =>  setContatos(res.data) )
                .catch(console.error)
    },[]);


    return <DataContext.Provider value={value}>{children}</DataContext.Provider>
}