import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        flex-direction: column;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin: auto;
        @media screen and (max-width: 550px){
            flex-direction: column;
        }
    `}
`;

export const Block = styled.div`
    ${({ theme }) => css`
        flex-grow: 1;
        display: flex;
        align-items: center;
    `}
`

export const ControlsWrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        border-radius: 4px;
        border: 1px solid ${theme.colors.gray};
        margin-bottom: 10px;
        background-color: ${theme.colors.white};
        min-width: 180px;
        justify-content: center;s
    `}
`
export const PaginationWrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
    `}
`

export const ToolbarItem = styled.div`
    ${({ theme,  marginLeftAuto, width }) => css`
        padding: 0px 2px;
        margin-left: ${marginLeftAuto ? 'auto' : null };
        width: ${width ? width : null };
    `}
`;


export const Button = styled.button`
    ${({ theme }) => css`
        background-color: transparent;
        border-color: transparent; 
    `}
`;

export const DownloadButton = styled(Button).attrs({ type: 'button' })`
    ${({ theme }) => css`
        text-transform: uppercase;
        font-weight: 700;
        font-size: 14px;
        background-color: ${theme.colors.mainColor};
        border-color: transparent; 
        color: ${theme.colors.white};
        padding: ${theme.spacings.gutter * 0.25}px ${theme.spacings.gutter * 0.5}px;
    `}
`

export const RoundedButton = styled.button`
    ${({ theme }) => css`
        padding: 5px;
        background-color: transparent;
        border-color: #000;
        border-radius: 50%; 
    `}
`
