import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme, active }) => css`
        transform: ${active ? 'translateY(-10px)' : 'translateY(0px)'};
        border-radius: 6px;
        background-color: #fff;
        box-shadow: 0px 30px 60px 0px rgb(0 0 0 / 8%);
        padding: ${theme.spacings.gutter / 2}px ${theme.spacings.gutter}px ${theme.spacings.gutter / 3}px;
        transition: all 0.3s linear;
        display: flex;
        align-items: left;
        flex-direction:column;
        @media (min-width: 576px) {
            flex-direction:row;
            align-items: center;
        }
    `}
`;
export const Meta = styled.div`
    ${({ theme }) => css`
        margin-right: 0;
        min-width: 175px;
    `}
`;
export const MetaItem = styled.a.attrs({ href: "#" })`
    ${({ theme }) => css`
        text-decoration: none;
        text-align: left;
        font-size: 16px;
        line-height: 20px;
        color: ${theme.colors.mainColor};
        display: block;
    `}
`;
export const MetaIcon = styled.i`
    ${({ theme }) => css`
        color: ${theme.colors.mainColor};
        padding-right: 8px;
        ::before{
            content: "\7d"
            margin-left: 0px;
        };
    `}
`;
export const Info = styled.div`
    ${({ theme }) => css`
        flex: 1;
        @media (min-width: 576px) {
            border-left: 1px solid ${theme.colors.secondaryColor};
            flex-direction:row;
            padding-left: 50px;

        }
        /* @media (max-width:991px){
        } */
    `}
`;
export const Photos = styled.div`
    ${({ theme }) => css`
        display: flex;
        margin: auto 0;
        align-items: center;
    `}
`;

export const Title = styled.h3`
    ${({ theme }) => css`
        font-weight: 600;
        font-size: 15px;
        line-height: 1.2;
        color: ${theme.colors.mainColor};
        margin-bottom: 3px;
        transition: all 0.3s linear;
        @media (min-width: 576px) {
            font-size: 16px;
        }
    `}
`;
export const Paragraph = styled.p`
    ${({ theme }) => css`
        margin-top: 0;
        margin-bottom: 0;
        color: ${theme.colors.primaryGray};
        line-height: 1.4rem;
        font-size: 12px;
        font-weight: 300;
    `}
`;
export const List = styled.ul`
    ${({ theme }) => css`
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        list-style-type: none;
        margin: 10px;
        padding: 0;
        @media (min-width: 576px){
            padding-left: 32px;
        }
        @media (min-width: 576px) and (max-width:991px){
            flex-wrap: wrap;
        }
    `}
`;
export const ListItem = styled.li`
    ${({ theme }) => css`
        width: 72px;
        cursor: pointer;
        :first-child {
            margin-right: 2px;
        }
        img {
            max-width: 100%;
            width: 65px;
            border-radius: 100px;
        }
    `}
`;
