import { createContext, useState, useEffect } from 'react';

export const PDFModalContext = createContext();

export const PDFModalContextProvider = ({ children }) => {

    const [open, setOpen] = useState(false);
    const [pdfUrl, setPDFUrl] = useState('');
    
    const value = {
        open, setOpen,
        pdfUrl, setPDFUrl
    };

    return <PDFModalContext.Provider value={value}>{children}</PDFModalContext.Provider>
}