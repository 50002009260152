import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import iconClose from '../../assets/icon-close-2.png';

export const Container = styled.nav`
    ${({ theme, bgColor }) => css`
        display: flex;
        flex-direction: column;
        padding: 25px;
        background-color: ${theme.colors.white};
        border: 1px solid #ccc;
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 20;
        @media (min-width: 576px) {
            position: absolute;
            width: auto;
            height: auto;
            min-width: 300px;
            top: unset;
            /* bottom: 45px; */
            left: 50%;
            transform: translateX(-50%);
            background-color: ${rgba(bgColor, 0.95)};
        }
        @media (min-width: 1280px) {
            position: absolute;
            width: auto;
            height: auto;
            min-width: 300px;
            top: unset;
            bottom: 45px;
            left: 50%;
            transform: translateX(-50%);
            background-color: ${rgba(bgColor, 0.95)};
        }
    `}
`;

export const CloseButtonContainer = styled.div`
    ${({ theme, bgColor }) => css`
        width: 100%;
        display: flex;
        justify-content: flex-end;
        @media (min-width: 576px) {
            display: none;
        }
    `}
`;

export const CloseButtonButton = styled.img.attrs({src: iconClose})`
    ${({ theme, bgColor }) => css`
    `}
`

export const NavbarItensWrapper = styled.ul`
    ${({ theme }) => css`
        width: 100%;
        padding: 0;
        margin: auto;
        /* height: 100%; */
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        overflow: auto;
    `}
`;
export const NavbarItem = styled.li`
    ${({ theme }) => css`
        min-width: 150px;    
        display: flex;
        justify-content: center;
    `}
`;
export const NavbarItemButton = styled.button.attrs({ type: 'button' })`
    ${({ theme }) => css`
        background-color: transparent;
        border: 1px solid transparent;
        text-transform: uppercase;
        color: ${theme.colors.gray};
        word-wrap: break-word;
        margin: auto;
        font-size: 12px;
        letter-spacing: 2.5px;
        text-decoration: none;
        :hover {
            letter-spacing: 2.5px;
            font-weight: 700;
            color: ${theme.colors.mainColor};
        }
    `}
`;