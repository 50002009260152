import P from 'prop-types';
import * as Styled from './styles';
import ModalFixedSize from '../../components/ModalFixedSize';
import iconClose from '../../assets/icon-close-2.png';
import TransparentContainer from '../../components/TransparentContainer';

import { useContext, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { AcessoSiteModalContext } from '../../context/AcessoSiteModalContext';

const AcessoSiteModal = () => {
    const theme = useTheme();
    const { open, setOpen } = useContext(AcessoSiteModalContext);

    useEffect(() => {
        const { title } = document;
        document.title = title + '- Acesse o site';
        return () => {
            document.title = title;
        }
    },[]);

    return ( <Styled.Container>
                <ModalFixedSize size={'lg'} show={open} onHideFn={() => setOpen(false)} >
                    <Styled.Content>
                        <Styled.CloseButton onClick={() => setOpen(false)}><img src={iconClose} /></Styled.CloseButton>
                        <Styled.Block flexBasis={'100%'}>
                            <TransparentContainer bgColor={theme.colors.white} alpha={0.5}>
                                <Styled.MainHeading>Saiba mais em nosso site</Styled.MainHeading>
                                <Styled.ButtonsContainer>
                                    <Styled.Button href={'https://johndeere.seedz.ag/'}>Acessar</Styled.Button>
                                </Styled.ButtonsContainer>
                            </TransparentContainer>
                        </Styled.Block>
                    </Styled.Content>
                </ModalFixedSize>
            </Styled.Container>
    );
};
AcessoSiteModal.propTypes = {};
export default AcessoSiteModal;