import P from 'prop-types';
import * as Styled from './styles';
import LinesSchedulePane from '../LinesSchedulePane';
const LinesSchedulePanes = ({ data, selectedIndex }) => {
    return (
        <Styled.Container>
            {data.map((item, i) => <LinesSchedulePane key={i} {...item} active={i === selectedIndex}/>)}
        </Styled.Container>
    );
};
LinesSchedulePanes.propTypes = {
    data: P.arrayOf(P.object).isRequired,
    selectedIndex: P.number.isRequired
};
export default LinesSchedulePanes;