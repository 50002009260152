import P from "prop-types";
import * as Styled from "./styles";
const DiagonalTab = ({ name, openInfoFn }) => {

  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.Text>{name}</Styled.Text>
        <Styled.Button onClick={openInfoFn}><Styled.PlusIcon />Mais informações</Styled.Button>
      </Styled.Content>
    </Styled.Container>
  );
};
DiagonalTab.propTypes = {
  name: P.string.isRequired,
  openInfoFn: P.func,
};
export default DiagonalTab;
