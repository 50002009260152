import styled, { css } from 'styled-components';
export const Container = styled.ul`
    ${({ theme }) => css`
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        /* padding-right: 1rem; */
        padding: 0;
        text-align: center;
        border-bottom: 0;
        @media (min-width: 576px) {
            /* width: 100%; */
            /* display: flex; */
            /* flex-wrap: wrap; */
            /* justify-content: center; */
            /* padding-right: 1rem; */
            /* text-align: center; */
            /* border-bottom: 0; */
        }
        /* @media (max-width: 1030px){
            width: calc(100% + 5rem );
        } */
    `}
`;