import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
        position: fixed;
        /* top: 40px; */
        bottom: 40px;
        right: 0;
        z-index: 18;
        display: flex;
        flex-direction: column;
        gap: ${theme.spacings.gutter / 2}px;
        @media (min-width: 576px) {
            top: 40px;
            bottom: unset;
        }
    `}
`;