import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
}
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// export const modalScreenView = (name) => {
//     logEvent(analytics, 'screen_view', {
//         modal_name: name,
//     });
// }

// export const clickEvent = (type, name) => {
//     logEvent(analytics, 'click_event', {
//         content_type: type,
//         content_item: name,
//     })
// }

// export const videoModalOpen = (name, id) => {
//     logEvent(analytics, 'video_modal', {
//         name: name,
//         id: id
//     })
// }

// export const videoModalTime = (name, id, seconds) => {
//     logEvent(analytics, 'video_modal', {
//         name: name,
//         id: id,
//         seconds: seconds,
//     })
// }

// export const setCNPJProperty = () => {
//     setUserProperties(analytics, { 
//         cnpj:'000.000.0000/0000-00',
//         handless: 'left'
//     })
// }