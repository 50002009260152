import { createContext, useState } from 'react';

export const VideoCarrosselModalContext = createContext();

export const VideoCarrosselModalContextProvider = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [videos, setVideos] = useState(['1_Tela_Inicial_v3.mp4', '2_Pulsacao_v3.mp4', '3_Troca_Automatica_de_Pontas_v3.mp4', '4_Compensacao_de_Curva_v3.mp4', '5_Bordadura_Calculadora_v3.mp4']);
    const value = { open, setOpen, videos, setVideos };

    return <VideoCarrosselModalContext.Provider value={value}>{children}</VideoCarrosselModalContext.Provider>
}