import { useEffect, useState } from 'react';
import P from 'prop-types';
import * as Styled from './styles';
const ModalVerticalFlexSize = ({ headerText, children, show, size = "sm", onHideFn, centered = false  }) => {

    const [modalWidth, setModalWidth] = useState('0px');
    const [modalHeight, setModalHeight] = useState('0px');
    
    useEffect(() => {
        const aspectRatio = window.innerWidth / window.innerHeight;
        const maxWidth = window.innerWidth - 20;
        const maxHeight = window.innerHeight - 110;
        

        if(aspectRatio > 1){
            setModalWidth(`${maxHeight * 9 / 16 }px`);
        } else {
            setModalWidth(`${maxWidth}px`);
        }
    },[])

    return (
        <Styled.Container show={show} modalwidth={modalWidth} size={size}  onHide={onHideFn} centered={centered}>
            {/* {(headerText !== '' || headerText !== undefined || headerText !== null) ? <Styled.Header closeButton >{headerText}</Styled.Header> : null } */}
            {(headerText === '' || headerText === undefined || headerText === null) ? null : <Styled.Header closeButton >{headerText}</Styled.Header> }
            { children }
        </Styled.Container>
    );
};
ModalVerticalFlexSize.propTypes = {
    headerText: P.string,
    children: P.node.isRequired,
    show: P.bool,
    size: P.string,
    onHideFn: P.func,
    centered: P.bool
};
export default ModalVerticalFlexSize;