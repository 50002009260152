import P from 'prop-types';
import * as Styled from './styles';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import Toolbar from './Toolbar';
import AlternativeToolbar from './AlternativeToolbar';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PDFViewer = ({ pdf, fileName, useToolbar = true, canDownload = true }) => {

    const toolbarPluginInstance = toolbarPlugin();
    const { Toolbar:DefaultToolbar } = toolbarPluginInstance;
    const getFilePluginInstance = getFilePlugin({
        fileNameGenerator:(file) => {
            return `${fileName}`;
        }
    });
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: (defaultTabs) => []
    });
    return (
        <Styled.Container>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
               
                <Viewer                 
                    plugins={[toolbarPluginInstance, getFilePluginInstance]}     
                    fileUrl={pdf} 
                    defaultScale={SpecialZoomLevel.PageFit} />
                 {useToolbar ?
                <DefaultToolbar>
                    {(props) => <AlternativeToolbar slots={props} canDownload={canDownload}/>}
                </DefaultToolbar>
                : null }
            </Worker>
        </Styled.Container>
    );
};
PDFViewer.propTypes = {
    pdf: P.string.isRequired,
    fileName: P.string.isRequired,
    useToolbar: P.bool,
    canDownload: P.bool
};
export default PDFViewer;