export const addEndDots = (string, size = 36) => {    
    if(string.length >= size){
        string = string.split(' ').slice(0, -1).join(' ');
        if(string.length >= size) {
            string = string.split(' ').slice(0, -1).join(' ');
            if(string.length >= size) {
                string = string.split(' ').slice(0, -1).join(' ');
            }
        }
        return `${string}[...]`;
    } else {
        return string;
    }
}

export const uppercaseFirstLetter = (string) => {
    let splitWords = string.toLowerCase().split(' ');
    for(let i = 0; i < splitWords.length; i++){ 
        splitWords[i] = splitWords[i].charAt(0).toUpperCase() + splitWords[i].substring(1);
    }
    return splitWords.join(' ');

}