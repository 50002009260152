import styled, { css } from 'styled-components';
import { Container as TransparentContainer } from '../../components/TransparentContainer/styles';
import { Container as ThumbnailButtonContainer } from '../../components/ThumbnailButton/styles';
export const Container = styled.div`
    ${({ theme }) => css`
        
    `}
`;
export const Content = styled.div`
    ${({ theme }) => css`
        /* padding: ${theme.spacings.gutter}px; */
        position: relative;
        display: flex;
        width: 100%;
        border-radius: 10px;
        border: 1px solid rgba(0,0,0,0.1);
        @media (max-width: 768px){
            flex-direction: column;
        }
    `}
`;
export const Block = styled.div`
    ${({ theme, flexBasis, center, altPadding }) => css`
        /* padding: ${theme.spacings.gutter}px; */
        display: flex;
        flex-basis: ${flexBasis};
        ${TransparentContainer}{
            gap:${theme.spacings.gutter}px;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: ${altPadding ? theme.spacings.gutter + (theme.spacings.gutter / 2) : theme.spacings.gutter}px;
            flex-grow: 1;
            /* display: ${center ? 'flex' : 'block'}; */
            display: flex;
        }
    `}
`;
export const PinWrapper = styled.div`
    ${({ theme, flexBasis, center }) => css`
        display: flex;
        background-color: #E7E8E6;
        border-radius: 4px;
        padding: 28px 0;
        width: 100%;
        @media (max-width: 768px){
            
        }
    `}
`;

export const TitleWrapper = styled.div`
    ${({ theme, flexBasis, center }) => css`
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: ${theme.spacings.gutter / 2 }px;
        border-bottom: 5px solid ${theme.colors.secondaryColor};
        @media (max-width: 768px){
            
        }
    `}
`;

export const ContentWrapper = styled.div`
    ${({ theme, flexBasis, center }) => css`
        display: flex;
        flex-direction: column;
        gap: ${theme.spacings.gutter / 2 }px;
        @media (max-width: 768px){
            
        }
    `}
`;
export const Pin = styled.img`
    ${({ theme }) => css`
        margin: 0 auto;  
    `}
`;
export const CloseButton = styled.button`
    ${({ theme }) => css`
        position: absolute;
        top: 10px;
        right: 10px;
        background: transparent;
        border: 1px solid transparent;
    `}
`;
export const Button = styled.button`
    ${({ theme }) => css`
        font-family: ${theme.fonts.mainFont};
        text-transform: uppercase;
        color: ${theme.colors.secondaryColor};
        padding: ${theme.spacings.gutter / 2 }px;
        background-color: ${theme.colors.mainColor};
        border: 1px solid transparent;
        border-radius: ${theme.rounds.big};
        width: fit-content;
        align-self: flex-end;
        img {
            margin-right: 5px;
        }
        @media (max-width: 768px){
            align-self: center;
        }
    `}
`;

export const MainHeading = styled.h1`
    ${({ theme }) => css`
        color: ${theme.colors.mainColor};
        font-weight: 700;
        font-size: 23px;
        text-transform: uppercase;
        text-align: center;
        @media (min-width: 576px) {
            font-size: 26px;
        }
    `}
`;

export const SecondaryHeading = styled.h4`
    ${({ theme }) => css`
        color: ${theme.colors.terciaryColor};
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        text-align: left;
    `}
`;
export const Paragraph = styled.p`
    ${({ theme }) => css`
        color: ${theme.colors.gray};
        font-weight: 300;
        font-size: 14px;
    `}
`;
