import styled, { css } from 'styled-components';
import { Container as TransparentContainer } from '../../components/TransparentContainer/styles';
export const Container = styled.div`
    ${({ theme }) => css`
        
    `}
`;
export const Content = styled.div`
    ${({ theme }) => css`
        /* padding: ${theme.spacings.gutter}px; */
        position: relative;
        display: flex;
        width: 100%;
        border-radius: 10px;
        border: 1px solid rgba(0,0,0,0.1);
        @media (max-width: 768px){
            flex-direction: column;
        }
    `}
`;
export const Block = styled.div`
    ${({ theme, flexBasis, center }) => css`
        /* padding: ${theme.spacings.gutter}px; */
        display: flex;
        flex-basis: ${flexBasis};
        ${TransparentContainer}{
            padding: ${theme.spacings.gutter}px;
            flex-grow: 1;
            /* display: flex; */
            display: ${center ? 'flex': 'block' };
            flex-direction: ${center ? 'column': 'unset' };
            justify-content: ${center ? 'center': 'unset' };
        }
    `}
`;
export const PDFWrapper = styled.div`
    ${({ theme, flexBasis, center }) => css`
            display: flex;
            gap: ${theme.spacings.gutter}px;
            flex-grow: 1; 
            @media (max-width: 768px){
                flex-direction: column;
            }
        }  
    `}
`

export const PDFBlock = styled.div`
    ${({ theme  }) => css`
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }
    `}
`

export const CloseButton = styled.button`
    ${({ theme }) => css`
        position: absolute;
        top: 10px;
        right: 10px;
        background: transparent;
        border: 1px solid transparent;
    `}
`;

export const MainHeading = styled.h1`
    ${({ theme }) => css`
        color: ${theme.colors.secondaryColor};
        font-weight: 700;
        font-size: 26px;
        text-transform: uppercase;
    `}
`;

export const SecondaryHeading = styled.h4`
    ${({ theme }) => css`
        color: ${theme.colors.mainColor};
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        text-align: center;
    `}
`;
export const Paragraph = styled.p`
    ${({ theme }) => css`
        color: ${theme.colors.white};
        font-weight: 300;
        font-size: 13px;
    `}
`;
