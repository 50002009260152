import P from 'prop-types';
import * as Styled from './styles';

import { MinusCircle } from '@styled-icons/boxicons-regular/MinusCircle'
import { PlusCircle } from '@styled-icons/boxicons-regular/PlusCircle';
import { ArrowIosForwardOutline } from '@styled-icons/evaicons-outline/ArrowIosForwardOutline'
import { ArrowIosBackOutline } from '@styled-icons/evaicons-outline/ArrowIosBackOutline'
const AlternativeToolbar = ({ slots, canDownload = true, zoomOutIcon = null, zooInIcon = null, previousPageIcon = null, nextPageIcon = null, downloadIcon = null }) => {
    const {
        CurrentPageLabel,
        CurrentPageInput,
        Download,
        EnterFullScreen,
        GoToNextPage,
        GoToPreviousPage,
        NumberOfPages,
        Print,
        ShowSearchPopover,
        Zoom,
        ZoomIn,
        ZoomOut,
    } = slots;

    return (
        <Styled.Container>
            <Styled.Block>
                <Styled.ControlsWrapper>
                    <Styled.PaginationWrapper>
                        <Styled.ToolbarItem>
                            <GoToPreviousPage>
                            {(props) => (
                                <Styled.Button onClick={props.onClick}>
                                    <ArrowIosBackOutline size={20}/>
                                </Styled.Button>
                            )}  
                            </GoToPreviousPage>
                        </Styled.ToolbarItem>
                        <Styled.ToolbarItem>
                            <CurrentPageLabel />
                        </Styled.ToolbarItem>
                        <Styled.ToolbarItem>
                            / <NumberOfPages />
                        </Styled.ToolbarItem>
                        <Styled.ToolbarItem>
                            <GoToNextPage>
                            {(props) => (
                                <Styled.Button onClick={props.onClick}>
                                    <ArrowIosForwardOutline size={20}/>
                                </Styled.Button>
                            )}
                            </GoToNextPage>
                        </Styled.ToolbarItem>
                    </Styled.PaginationWrapper>
                    <Styled.ToolbarItem>
                        <ZoomOut>
                            {(props) => (<Styled.Button onClick={props.onClick}>
                                    <MinusCircle size={20}/>
                                </Styled.Button>)}
                        </ZoomOut>
                    </Styled.ToolbarItem>
                    <Styled.ToolbarItem>
                        <ZoomIn>
                        {(props) => (
                            <Styled.Button onClick={props.onClick}>
                                <PlusCircle size={20} />
                            </Styled.Button>
                        )}
                        </ZoomIn>
                    </Styled.ToolbarItem>
                </Styled.ControlsWrapper>
            </Styled.Block>
            {canDownload ? 
            <Styled.Block>
                <Styled.ToolbarItem>
                    <Download>
                        {(props) => ( <Styled.DownloadButton onClick={props.onClick}>
                                Download
                            </Styled.DownloadButton>
                        )}
                    </Download>
                </Styled.ToolbarItem>
            </Styled.Block>
            
            : null}
        </Styled.Container>
    );

}
AlternativeToolbar.propTypes = {
    slots: P.object.isRequired,
    canDownload: P.bool,
    zoomOutIcon: P.element,
    zooInIcon: P.element, 
    previousPageIcon:P.element, 
    nextPageIcon: P.element, 
    downloadIcon: P.element
};

export default AlternativeToolbar