import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateY(-50%);

        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin: auto;
        @media screen and (max-width: 550px){
            flex-direction: column;
        }
    `}
`;

export const Block = styled.div`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
    `}
`

export const ToolbarItem = styled.div`
    ${({ theme,  marginLeftAuto, width }) => css`
        padding: 0px 2px;
        margin-left: ${marginLeftAuto ? 'auto' : null };
        width: ${width ? width : null };
    `}
`;


export const Button = styled.button`
    ${({ theme }) => css`
        background-color: transparent;
        border-color: transparent; 
    `}
`;

export const DownloadButton = styled(Button).attrs({ type: 'button' })`
    ${({ theme }) => css`
        background-color: ${theme.colors.mainColor};
        border-color: transparent; 
    `}
`

export const RoundedButton = styled.button`
    ${({ theme }) => css`
        padding: 5px;
        background-color: transparent;
        border-color: #000;
        border-radius: 50%; 
    `}
`
