import P from 'prop-types';
import * as Styled from './styles';
const LinesScheduleNavbarItem = ({ mainText, smallText, href, active = false, selectIndexFn = () => {} }) => {

    return (
        <Styled.Container active={active} onClick={selectIndexFn}>
            <Styled.Link active={active} href={href}>
                <Styled.Heading active={active}>
                    {mainText}
                    <Styled.SmallText active={active}>{smallText}</Styled.SmallText>
                </Styled.Heading>
            </Styled.Link>
        </Styled.Container>
    );
};
LinesScheduleNavbarItem.propTypes = {
    mainText: P.string.isRequired,
    smallText: P.string,
    href: P.string,
    active: P.bool,
    selectIndexFn: P.func
};
export default LinesScheduleNavbarItem;