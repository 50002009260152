import P from 'prop-types';
import { useTheme } from 'styled-components';
import * as Styled from './styles';

import ModalFlexSize from '../../components/ModalFlexSize';
import { useContext, useEffect } from 'react';
import { PDFModalContext } from '../../context/PDFModalContext';

import iconClose from '../../assets/icon-close-2.png';

import TransparentContainer from '../../components/TransparentContainer';
import PDFViewer from '../../components/PDFViewer';

const PDFModal = () => {
    const theme = useTheme();
    const { pdfUrl, open, setOpen  } = useContext(PDFModalContext);

    useEffect(() => {
        const { title } = document;
        document.title = title + '- Folheteria ';
        return () => {
            document.title = title
        };
    },[])

    return ( <Styled.Container>
                <ModalFlexSize size={'lg'} show={open} onHideFn={() => setOpen(false)} headerText={''} >
                    <Styled.Content>
                        <Styled.CloseButton onClick={() => setOpen(false)}><img src={iconClose} /></Styled.CloseButton>
                        <Styled.Block flexBasis={'100%'}>
                            <TransparentContainer bgColor={theme.colors.white} alpha={0.5}>
                                <Styled.PDFWrapper>
                                    <Styled.PDFBlock>
                                        <PDFViewer canDownload={true} useToolbar={true} downloadIcon={false} pdf={pdfUrl} fileName={'Teste'} />
                                    </Styled.PDFBlock>
                                </Styled.PDFWrapper>
                            </TransparentContainer>
                        </Styled.Block>
                    </Styled.Content>
                </ModalFlexSize>
            </Styled.Container>
    );
};
PDFModal.propTypes = {};
export default PDFModal;