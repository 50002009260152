import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        position: relative;
        height: 100vh;
    `}
`;

export const Item = styled.div`
    ${({ theme, index,  show }) => css`
        position: absolute;
        transition: opacity 100ms ease, transform 300ms ease;
        opacity: ${show ? 1 : 0};
        transform: ${show ? 'translateX(0px)' : 'translateX(50px)' };
        width: 100%;
        z-index: ${1000 - index};
    `}
`;

export const ImageBlock = styled.div`
    ${({ theme }) => css`
        
    `}
`;
export const Image = styled.img`
    ${({ theme }) => css`
        
        
    `}
`;