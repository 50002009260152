import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
        
    `}
`;
export const ControlButton = styled.button`
    ${({ theme, on }) => css`
        border-radius: 50%;
        border: 1px solid transparent;
        background-color: transparent;
    `}
`;