import { createContext, useState } from 'react';

export const VideoModalContext = createContext();

export const VideoModalContextProvider = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [video, setVideo] = useState({ link: '', horizontal: true });
    const value = { open, setOpen, video, setVideo };

    return <VideoModalContext.Provider value={value}>{children}</VideoModalContext.Provider>
}