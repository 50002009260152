import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import reportWebVitals from './reportWebVitals';
import { GlobalStyles } from './styles/global-styles';
import { theme } from './styles/theme';
import ContextsGroup from './context/ContextsGroup';
import 'bootstrap/dist/css/bootstrap.min.css';
import MainMenu from './templates/MainMenu';
import ComponentsWrapper from './templates/ComponentsWrapper';
import WindowFunctions from './services/WindowFunctions';
import KeypressEvents from './services/KeypressEvents';

import { DataContextProvider } from './context/DataContext';


ReactDOM.render(
  <React.StrictMode>
      <ThemeProvider theme={theme}>
        <DataContextProvider>
          <ContextsGroup>
            <WindowFunctions>
              {/* <KeypressEvents> */}

                <GlobalStyles />
                <MainMenu />
                <ComponentsWrapper />
                
              {/* </KeypressEvents> */}
            </WindowFunctions>
          </ContextsGroup>
        </DataContextProvider>
      </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
