import P from 'prop-types';
import { useTheme } from 'styled-components';
import * as Styled from './styles';

import ModalFlexSize from '../../components/ModalFlexSize';
import ModalFixedSize from '../../components/ModalFixedSize';
import { useContext, useEffect } from 'react';
import { BemVindoModalContext } from '../../context/BemVindoModalContext';

import iconClose from '../../assets/icon-close-2.png';
import iconFile from '../../assets/icon-file.png';
import pin from '../../assets/pin.png';

import TransparentContainer from '../../components/TransparentContainer';

const BemVindoModal = () => {
    const theme = useTheme();
    const { open, setOpen } = useContext(BemVindoModalContext);

    useEffect(() => {
        const { title } = document;
        document.title = title + '- Bem Vindo';
        return () => {
            document.title = title;
        }
    },[])

    return ( <Styled.Container>
                <ModalFixedSize size={'md'} show={open} onHideFn={() => {
                    setOpen(false); 
                    window.PlayInitialVideo();
                }} headerText={''} >
                    <Styled.Content>
                        <Styled.CloseButton onClick={() => setOpen(false)}><img src={iconClose} /></Styled.CloseButton>
                        <Styled.Block flexBasis={'100%'} altPadding={true}>
                            <TransparentContainer bgColor={theme.colors.white} alpha={1.0}>
                                <Styled.TitleWrapper>
                                    <Styled.MainHeading>É um prazer receber você</Styled.MainHeading>
                                </Styled.TitleWrapper>
                                <Styled.PinWrapper>
                                    <Styled.Pin src={pin}/>
                                </Styled.PinWrapper>
                                <Styled.ContentWrapper>
                                    <Styled.Paragraph>Clique nos pins espalhados pelo ambiente para navegar pela plataforma e interagir com os conteúdos.</Styled.Paragraph>
                                </Styled.ContentWrapper>
                            </TransparentContainer>
                        </Styled.Block>
                    </Styled.Content>
                </ModalFixedSize>
            </Styled.Container>
    );
};
BemVindoModal.propTypes = {};
export default BemVindoModal;