export const removeNonNumeric = (string) => string.replace(/\D/g,'');
export const replaceAllSpecialCharacters = (string) => {
    const caracters = ['"',     "'",     '‘',       '’',       '“',       '”',       '–',      '—'];
    const codes   = ['&#34;', '&#39;', '&lsquo;', '&rsquo;', '&ldquo;', '&rdquo;', '&#150;', '&#151;'];
    let resultado = string;    
    codes.forEach((code, i) => {
        const reg = new RegExp(code, 'g');
        resultado = resultado.replace(reg, caracters[i]);
    });
    return resultado;
}

export const whatsappHrefString = (number) => `https://api.whatsapp.com/send?l=pt&phone=+55${removeNonNumeric(number)}`;