import P from 'prop-types';
import * as Styled from './styles';
import { useTheme } from 'styled-components';
import { useState, useEffect, useRef } from 'react';
import jdLogo from '../../assets/john-deere-logo.png';
import homeIcon from '../../assets/icon-open-home.png';
import volumeOnIcon from '../../assets/icon-material-volume-up.png';
import chatIcon from '../../assets/icon-chat.png';

import { VolumeUp, VolumeOff } from  '@styled-icons/evaicons-solid';


import BannerSubMenu from '../BannerSubMenu';

const BannerMenu = ({ itensMenu, closeDialogTabFn }) => {
    const theme = useTheme();

    const [volume, setVolume] = useState(true);
    const [menuOpen, setMenuOpen] = useState(itensMenu.map((_) => ({ open: false } )));
    const [collpased, setCollapsed] = useState(false);

    const timeoutAndUncheck = () => {
        setTimeout(() => {
            setMenuOpen(prev => prev.map(item => ( { open:false } ) ) )    ;
        }, 3000)
    }

    const goHome = () => {
        window.Navigation("start");
    }

    const openSubmenu = (target, index)  => {
        if(menuOpen[index].open === true){
            setMenuOpen(prev => prev.map(item => ( { open:false } ) ) )    ;
            return;
        }
        setMenuOpen(prev => {
            const newState = prev.map(item => ({ open:false }));
            newState[index].open = true;
            return newState;
        });
    }

    const closeSubmenusAndRunFn = (fn, index) => {
        setMenuOpen(prev => {
            const newState = prev.map(item => ({ open:false }));
            newState[index].open = true;
            return newState;
        });
        fn();
        setCollapsed(false);
        timeoutAndUncheck();
    }

    const closeSubmenus = () => {
        setMenuOpen(prev => prev.map(item => ({ open:false })));
    }

    return (
        <Styled.Container>
            <Styled.Navbar>
                <Styled.LogoContainer>
                    <Styled.LogoAnchor href={'https://www.deere.com.br/pt/'}>
                        <Styled.Logo src={jdLogo}/>
                    </Styled.LogoAnchor>
                </Styled.LogoContainer>
                <Styled.Controls>
                    <Styled.Control onClick={goHome}><img src={homeIcon}/></Styled.Control>
                    <Styled.Control onClick={() => {setVolume(!volume); window.ToggleSound();}}>
                        {volume ? <VolumeUp size={20} fill={theme.colors.gray} /> : <VolumeOff fill={theme.colors.gray} size={20} />}
                        {/* <img src={volume ? volumeOnIcon : volumeOnIcon}/> */}
                    </Styled.Control>
                </Styled.Controls>
                <Styled.Triangle borderColor={theme.colors.secondaryColor} />
                <Styled.InvertTriangle borderColor={theme.colors.secondaryColor} bgColor={theme.colors.mainColor} />
                <Styled.CollapseButton onClick={() => setCollapsed(prev => !prev)}><Styled.ToggleIcon />Menu</Styled.CollapseButton>
                <Styled.NavbarItensWrapper active={collpased}>
                    {itensMenu.map((item, i) => <Styled.NavbarItem key={item.text}>
                        <Styled.NavbarItemButton actived={menuOpen[i].open} onClick={ 
                                (item.openModalFn === undefined || item.openModalFn === null ) 
                                ? 
                                () => openSubmenu(item.target, i) 
                                : () => closeSubmenusAndRunFn(item.openModalFn, i) }>
                            {item.text}
                        </Styled.NavbarItemButton>
                        {(menuOpen[i].open && item.anchors.length > 0) && <BannerSubMenu anchors={item.anchors} closeFn={closeSubmenus} closeMainMenu={() => setCollapsed(false)}/>}
                        </Styled.NavbarItem>
                        )
                    }
                </Styled.NavbarItensWrapper>
            <Styled.InvertTriangle borderColor={theme.colors.mainColor} bgColor={'transparent'} />
            </Styled.Navbar>
        </Styled.Container>
    );
};
BannerMenu.propTypes = {
    itensMenu: P.arrayOf(P.object),
    closeDialogTabFn:P.func
};
export default BannerMenu;