import { createContext, useState, useEffect } from 'react';

export const DiagonalTabContext = createContext();

export const DiagonalTabContextProvider = ({ children }) => {

    const [open, setOpen] = useState(true);
    const [name, setName] = useState([]);
    const [folheriaData, setFolheriaData] = useState();
    const [moreInfoData, setMoreInfoData] = useState();
    
    const [data, setData] = useState([])

    const addData = (data) => {
        setData(prev => [...prev, data] );
    }

    const clearAndAddData = (data) => {
        console.log(data)
        setData([data]);
    }

    const clearData = () => setData([]);

    // useEffect(() => {
    //     console.log(data)
    // },[data])

    const value = {
        open, setOpen,
        name, setName,
        folheriaData, setFolheriaData,
        moreInfoData, setMoreInfoData,
        data, addData,
        clearData,  clearAndAddData
    }

    return <DiagonalTabContext.Provider value={value}>{children}</DiagonalTabContext.Provider>
}