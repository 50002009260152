import { createContext, useState } from 'react';

export const FolheteriaModalContext = createContext();

export const FolheteriaModalContextProvider = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [pdf, setPdf] = useState('');
    const value = { open, setOpen, pdf, setPdf };

    return <FolheteriaModalContext.Provider value={value}>{children}</FolheteriaModalContext.Provider>
}