import P from 'prop-types';
import * as Styled from './styles';
import ModalFixedSize from '../../components/ModalFixedSize';
import { useContext, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import iconClose from '../../assets/icon-close-2.png';
import TransparentContainer from '../../components/TransparentContainer';
import { ConcessionarioModalContext } from '../../context/ConcessionarioModalContext';
import { DataContext } from '../../context/DataContext';
import { acentoMaiuscula } from '../../services/estadosFn';
import { uppercaseFirstLetter } from '../../services/stringsFn';
import DealerInfo from './DealerInfo';



const ConcessionarioModal = () => {

    const theme = useTheme();
    const { open, setOpen } = useContext(ConcessionarioModalContext);

    const { contatos } = useContext(DataContext);
    const [stateCities, setStateCities] = useState([]);
    const [state, setState] = useState();
    const [city, setCity] = useState()
    
    const renderEstados = () => {
        return Object.keys(contatos).sort((a,b) => a.localeCompare(b)).map((key,i) => <option key={`${key}${i}`} value={key}>{acentoMaiuscula(key)}</option>)
    }

    const selectEstadoChangeFn = (event) => {
        const { value } = event.target;
        setState(value);
        if(contatos[value] !== undefined || contatos[value] !== null){
            const cities = contatos[value].map((contact) => contact.cidade);
            setStateCities(cities);
        }
    }

    const selectCityChangeFn = (event) => {
        const { value } = event.target;
        setCity(value);
    }

    const getDealersByCity = () => {
        return contatos[state].filter((obj) => obj.cidade === city);
    }

    useEffect(() => {
        const { title } = document;
        document.title = title + '- Concessionário';
        return () => {
            document.title = title;
        }
    },[]);

    return ( <Styled.Container>
                <ModalFixedSize size={'lg'} show={open} onHideFn={() => setOpen(false)} >
                    <Styled.Content>
                        <Styled.CloseButton onClick={() => setOpen(false)}><img src={iconClose} /></Styled.CloseButton>
                        <Styled.Block flexBasis={'100%'}>
                            <TransparentContainer bgColor={theme.colors.white} alpha={1}>
                                <Styled.MainHeading>Encontre um concessionário próximo de você<hr /></Styled.MainHeading>
                                <Styled.Form>
                                    <Styled.Select onChange={selectEstadoChangeFn}>
                                        <option>- selecione o estado</option>
                                        {renderEstados()}
                                    </Styled.Select>
                                    <Styled.Select onChange={selectCityChangeFn}>
                                        <option>- selecione a cidade</option>
                                        {stateCities.sort((a,b) => a.localeCompare(b)).map((city, i) => <option key={`${city}${i}`} value={city}>{uppercaseFirstLetter(city)}</option> )}
                                    </Styled.Select>
                                </Styled.Form>
                                
                                {city && (<Styled.DealersWrapper>
                                    {getDealersByCity().map((dealer, i) =>  <DealerInfo key={`${dealer.cidade}${i}`} {...dealer} /> )}
                                </Styled.DealersWrapper>)}

                                <Styled.Paragraph>
                                Para mais informações e outras localizações, acesse <a href={'https://dealerlocator.deere.com/servlet/country=BR'} target="_blank">aqui</a>.
                                </Styled.Paragraph>
                            </TransparentContainer>
                        </Styled.Block>
                    </Styled.Content>
                </ModalFixedSize>
            </Styled.Container>
    );
};
ConcessionarioModal.propTypes = {};
export default ConcessionarioModal;