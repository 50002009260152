import P from 'prop-types';
import * as Styled from './styles';
const VerticalVideo = ({ videoUrl }) => {
    return (
        <Styled.Container>
            <Styled.Iframe
            src={videoUrl} 
            style={{position: 'absolute', top: 0, left: 0}}
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen />
        </Styled.Container>
    );
};
VerticalVideo.propTypes = {
    videoUrl: P.string.isRequired,
};
export default VerticalVideo;