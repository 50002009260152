import { createGlobalStyle } from 'styled-components';
import { theme } from './theme';


export const GlobalStyles = createGlobalStyle`
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: ${theme.fonts.mainFont};
}
body {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
}
@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        transform: translateY(20px)
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}
::-webkit-scrollbar {
    width: 10px;    

}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 5px;
}
   
::-webkit-scrollbar-thumb {
    background: #367C2B; 
    border-radius: 5px;
}
  
::-webkit-scrollbar-thumb:hover {
    background: #367C2B; 
}
`
/* @font-face {
    font-family: 'Museo Sans';
    src: url(${museoSans100italic});
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: 'Museo Sans';
    src: url(${museoSans100});
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Museo Sans';
    src: url(${museoSans300italic});
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Museo Sans';
    src: url(${museoSans300});
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Museo Sans';
    src: url(${museoSans500});
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Museo Sans';
    src: url(${museoSans700italic});
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'Museo Sans';
    src: url(${museoSans700});
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Museo Sans';
    src: url(${museoSans900italic});
    font-weight: 900;
    font-style: italic;
}
@font-face {
    font-family: 'Museo Sans';
    src: url(${museoSans900});
    font-weight: 900;
    font-style: normal;
} */
