import P from 'prop-types';
import { removeNonNumeric, whatsappHrefString } from '../../../services/utils';
import * as Styled from './styles';
const DealerInfo = ({ cidade, contato, dealer }) => {    
    return (
        <Styled.Container>
            <Styled.Block>
                <Styled.Name>
                    {dealer !== 'Procure por uma cidade próxima ou entre em contato com nossa central.' && <Styled.LocationIcon />}{dealer}
                </Styled.Name>
            </Styled.Block>
            <Styled.Block>
                <Styled.Phone href={`tel:${removeNonNumeric(contato)}`}>
                   <Styled.PhoneIcon /> {contato}
                </Styled.Phone>
            </Styled.Block>
            <Styled.Block>
                <Styled.Whatsapp href={whatsappHrefString(contato)}>
                   <Styled.WhatsappIcon /> Whatsapp
                </Styled.Whatsapp>
            </Styled.Block>
        </Styled.Container>
    );
};
DealerInfo.propTypes = {
    cidade: P.string.isRequired,
    contato: P.string.isRequired,
    dealer: P.string.isRequired,
};
export default DealerInfo;