import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import bgDetail from '../../assets/diagonal-tab-detail.png';
import listPlusIcon from '../../assets/icon-plus-2.png';
export const Container = styled.div`
    ${({ theme }) => css`
        background-color: ${rgba(theme.colors.white, 0.9)};
        transform: skewX(15deg);
        height: 96px;
        box-shadow: 0px 0px 15px #000;
    `}
`;
export const Content = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: 386px;
        height: 100%;
        transform: skewX(-15deg);
        background-image: url(${bgDetail});
        background-position: center right;
        background-size: contain;
        background-repeat: no-repeat;
    `}
`;
export const PlusIcon = styled.img.attrs({ src: listPlusIcon })`
    ${({ theme }) => css`
        margin: auto 0;
        margin-right: 10px;
    `}

`
export const Text = styled.span`
    ${({ theme }) => css`
        text-transform: uppercase;
        color: ${theme.colors.gray};
        font-weight: 700;
        margin-left: 20px;
        font-size: 16px;
        width: 90%;
        margin-right: 50px;
    `}
`;
export const Button = styled.button.attrs({ type: 'button' })`
    ${({ theme }) => css`
        margin-left: 25px;
        display: flex;
        text-transform: uppercase;
        color: ${theme.colors.gray};
        font-weight: 400;
        font-size: 16px;
        background-color: transparent;
        border: 1px solid transparent;
    `}
`;