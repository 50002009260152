import { DiagonalTabContextProvider } from "./DiagonalTabContext";
import { MaisInformacoesModalContextProvider } from "./MaisInformacoesModalContext";
import { FolheteriaModalContextProvider } from "./FolheteriaModalContext";
import { AcessoSiteModalContextProvider } from "./AcessoSiteModalContext";
import { ConcessionarioModalContextProvider } from "./ConcessionarioModalContext";
import { AgendaModalContextProvider } from "./AgendaModalContext";

import { PDFModalContextProvider } from "./PDFModalContext";
import { VideoModalContextProvider } from "./VideoModalContext";
import { VideoCarrosselModalContextProvider } from "./VideoCarrosselModalContext";
import { BannerModalContextProvider } from "./BannerModalContext";
import { BemVindoModalContextProvider } from "./BemVindoModalContext";
import { LinkExternoModalContextProvider } from "./LinkExternoModalContext";


const ContextsGroup = ({ children }) => (
  <DiagonalTabContextProvider>
    <MaisInformacoesModalContextProvider>
        <FolheteriaModalContextProvider>
          <AcessoSiteModalContextProvider>
            <ConcessionarioModalContextProvider>
              <AgendaModalContextProvider>
                <PDFModalContextProvider>
                  <BemVindoModalContextProvider>
                    <VideoModalContextProvider>
                      <VideoCarrosselModalContextProvider>
                        <BannerModalContextProvider>
                          <LinkExternoModalContextProvider>
                            {children}
                          </LinkExternoModalContextProvider>
                        </BannerModalContextProvider>
                      </VideoCarrosselModalContextProvider>
                    </VideoModalContextProvider>
                  </BemVindoModalContextProvider>
                </PDFModalContextProvider>
              </AgendaModalContextProvider>
            </ConcessionarioModalContextProvider>
          </AcessoSiteModalContextProvider>
        </FolheteriaModalContextProvider>
    </MaisInformacoesModalContextProvider>
  </DiagonalTabContextProvider>
);

export default ContextsGroup;
