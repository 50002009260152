export const theme = {
    colors:{
        white: '#fff',
        black: '#000',
        mainDark: '#231f20',
        gray: '#54585A',
        mainColor: "#367C2B", 
        secondaryColor: "#FFDE00",
        terciaryColor: "#597b3f",
        quaternaryColor: "#ffee98",
        transparent: '#00000000',
    },
    fonts:{
        mainFont: 'Verdana',
        secondaryFont: 'Roboto'
    },
    spacings:{
        gutter: 30,
        gridColumn: 70,
        menuSpacing: 80,
    },
    rounds: {
        small: '4px',
        big: '50px',
        circle: '50%',
    },
    transitions:{
        timing: {
            mainTiming: 'cubic-bezier(0.175, 0.885, 0.320, 1.275)'
        }
    }
}