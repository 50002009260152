import P from 'prop-types';
import { Component } from 'react';
import { useTheme } from 'styled-components';
import * as Styled from './styles';
import { Clock } from '@styled-icons/bootstrap/Clock';

import ModalFlexSize from '../../components/ModalFlexSize';
import { useContext, useEffect } from 'react';
import { AgendaModalContext } from '../../context/AgendaModalContext';
import { DataContext } from '../../context/DataContext';

import iconClose from '../../assets/icon-close-2.png';

import TransparentContainer from '../../components/TransparentContainer';
import LinesSchedule from '../../components/LinesSchedule';
import LinesSchedulePaneLine from '../../components/LinesSchedulePaneLine';

const AgendaModal = () => {

    const theme = useTheme();
    const { open, setOpen  } = useContext(AgendaModalContext);
    const { lecture, machine, speaker } = useContext(DataContext);

    useEffect(() => {
        const { title } = document;
        document.title = title + '- Agenda';
        return () => {
            document.title = title
        };
    },[]);

    const findSpeakerById = (id) => {
        return speaker.find((item) => item.id === id);
    }

    const reducedData = (array) => array.reduce((acc, value) => {
        const date = new Date(`${value.date}T${value.time_start}:00`);
        const dateString = date.toLocaleDateString();
        if(acc[`_${dateString.replace(/\//g, '_' )}`] === undefined){
            acc[`_${dateString.replace(/\//g, '_' )}`] = []
        }
        acc[`_${dateString.replace(/\//g, '_' )}`].push({ ...value, date_object: date })
        return acc;
    }, {});
    
    const reduceByArea = (array) => {
        return array.reduce((acc, value, i) => {
            if(acc[`_${value.area.toLowerCase().replace(/ /g, '_')}`] === undefined){
                if(value.area === ''){
                    acc[`_intervaloe_${i}`] = []
                } else {
                    acc[`_${value.area.toLowerCase().replace(/ /g, '_')}`] = []
                }
            }
            if(value.area === ''){
                acc[`_intervaloe_${i}`].push(value)
            } else {
                acc[`_${value.area.toLowerCase().replace(/ /g, '_')}`].push(value)
            }
            return acc;
        },{});
    }

    const orderArrayByArea = (obj) => {
        return obj.map((item) => {
            return Object.keys(item).map((subitem, i) => item[subitem].sort((a,b) => {
                return new Date(`${a.date}T${a.time_start}:00`) - new Date(`${b.date}T${b.time_start}:00`)
            }) );
        })
    }

    const renderOrdered = (array) => {
        
        return array.map((item, c) => ({ children:item.map((event, k) => {
                        return <Styled.AreaWrapper key={`${event[0].date}.${event[0].time_start}`} bgColor={event[0].area} >
                                    {event.map( (e, i) => <Styled.InsideWrapper key={`${e.time_end}.${i}`} >
                                        {(i === 0 && e.area !== '') && <Styled.AreaWrapperContent><Styled.AreaTitle>{(e.area === 'Apresentação Especial2' ? "Apresentação Especial": e.area)}</Styled.AreaTitle></Styled.AreaWrapperContent> }
                                        <LinesSchedulePaneLine 
                                                title={e.title} description={'Descricao'} 
                                                startDate={new Date(`${e.date}T${e.time_start}`)} endDate={new Date(`${e.date}T${e.time_end}`)} 
                                                place={<Styled.DataWrapper><Clock size={20} />{e.time_start} - {e.time_end}</Styled.DataWrapper>}
                                                list={
                                                    e.speaker.map((s, c) =>  ({ 
                                                            name: findSpeakerById(s) ? findSpeakerById(s).name : '', 
                                                            imgUrl: findSpeakerById(s) ? `${process.env.REACT_APP_PHOTOS_SPEAKER_DIR}${findSpeakerById(s).file}` : ''
                                                        })
                                                        )
                                                    }
                                                />
                                    </Styled.InsideWrapper>
                                        ) }
                            </Styled.AreaWrapper> 
                            }
                        )            
            })
        );
    }

    const renderData = (data) => {
        const reduced = Object.keys(data).map((key, i) => reduceByArea(data[key]));
        const ordered = orderArrayByArea(reduced);
        return renderOrdered(ordered);
    }

    return ( <Styled.Container>
                <ModalFlexSize size={'lg'} show={open} onHideFn={() => setOpen(false)} headerText={''} >
                    <Styled.Content>
                        <Styled.CloseButton onClick={() => setOpen(false)}><img src={iconClose} /></Styled.CloseButton>
                        <Styled.Block flexBasis={'100%'}>
                            <TransparentContainer bgColor={theme.colors.white} alpha={0.9}>
                                <Styled.HeadingContainer>
                                    <Styled.MainHeading>Arena do Conhecimento</Styled.MainHeading>
                                    <Styled.SecondaryHeading>Acompanhe a programação ao vivo</Styled.SecondaryHeading>

                                </Styled.HeadingContainer>
                                {lecture !== undefined && renderData(reducedData(lecture)).length > 0 && <LinesSchedule data={renderData(reducedData(lecture))}/>}
                            </TransparentContainer>
                        </Styled.Block>
                    </Styled.Content>
                </ModalFlexSize>
            </Styled.Container>
    );
};
AgendaModal.propTypes = {};
export default AgendaModal;