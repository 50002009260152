import styled, { css } from 'styled-components';
import { Container as TransparentContainer } from '../../components/TransparentContainer/styles';
export const Container = styled.div`
    ${({ theme }) => css`
        
    `}
`;
export const Content = styled.div`
    ${({ theme }) => css`
        /* padding: ${theme.spacings.gutter}px; */
        position: relative;
        display: flex;
        width: 100%;
        border-radius: 10px;
        border: 1px solid rgba(0,0,0,0.1);
        
    `}
`;
export const Block = styled.div`
    ${({ theme, flexBasis }) => css`
        /* padding: ${theme.spacings.gutter}px; */
        display: flex;
        flex-basis: ${flexBasis};
        ${TransparentContainer}{
            border-radius: ${theme.rounds.small}; 
            padding: ${theme.spacings.gutter}px;
            flex-grow: 1
        }
    `}
`;
export const CloseButton = styled.button`
    ${({ theme }) => css`
        position: absolute;
        top: 10px;
        right: 10px;
        background: ${theme.colors.white};
        border: 1px solid transparent;
        border-radius: 50%;
        z-index:1500;
    `}
`;

export const Anchor = styled.a.attrs({ target: 'blank'})`
    ${({ theme }) => css`
        
    `}
`;

export const Banner = styled.img`
    ${({ theme }) => css`
        width: 100%;
    `}
`;