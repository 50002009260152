import styled, { css } from 'styled-components';
import { Container as TransparentContainer } from '../../components/TransparentContainer/styles';
export const Container = styled.div`
    ${({ theme }) => css`
        
    `}
`;
export const Content = styled.div`
    ${({ theme }) => css`
        /* padding: ${theme.spacings.gutter}px; */
        position: relative;
        display: flex;
        width: 100%;
        border-radius: 10px;
        border: 1px solid rgba(0,0,0,0.1);
        
    `}
`;
export const Block = styled.div`
    ${({ theme, flexBasis }) => css`
        /* padding: ${theme.spacings.gutter}px; */
        display: flex;
        flex-basis: ${flexBasis};
        ${TransparentContainer}{
            padding: ${theme.spacings.gutter}px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }
    `}
`;
export const CloseButton = styled.button`
    ${({ theme }) => css`
        position: absolute;
        top: 10px;
        right: 10px;
        background: transparent;
        border: 1px solid transparent;
    `}
`;

export const MainHeading = styled.h1`
    ${({ theme }) => css`
        color: ${theme.colors.mainColor};
        font-weight: 700;
        font-size: 25px;
        text-transform: uppercase;
        display: inline-block;
        text-align: center;
        hr {
            height: 5px;
            background-color: ${theme.colors.secondaryColor};
        }
        @media (min-width: 567px ){
            padding: 0 80px;
        }
    `}
`;

export const SecondaryHeading = styled.h4`
    ${({ theme }) => css`
        color: ${theme.colors.mainColor};
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        text-align: center;
    `}
`;
export const Paragraph = styled.p`
    ${({ theme }) => css`
        color: ${theme.colors.gray};
        font-weight: 300;
        font-size: 13px;
        text-align: center;
        margin-top: 30px;
        a {
            color: inherit;
        }
    `}
`;

export const ButtonsContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        width: ${theme.spacings.gutter + (theme.spacings.gridColumn * 2)}px;
        margin: auto;
        justify-content: space-evenly;
        gap: ${ theme.spacings.gutter }px;
        padding: ${ theme.spacings.gutter }px 0 ;
    `}
`;
export const Button = styled.button`
    ${({ theme }) => css`
        background-color: transparent;
        border: 1px solid transparent;
    `}
`;

export const Form = styled.form`
    ${({ theme }) => css`
        background-color: transparent;
        border: 1px solid transparent;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
    `}
`;
export const Select = styled.select`
    ${({ theme }) => css`
        padding: 8px ${theme.spacings.gutter}px; 
        flex-grow: 1;
        font-size: 12px;
        background-color: #E5E6E6;
        text-align: center;
        border: 1px solid transparent;
        max-width: 250px;
    `}
`;
export const DealersWrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        justify-content: center;
        margin: 25px 0;
    `}
`