import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        position: relative;
    `}
`;
export const Iframe = styled.iframe`
    ${({ theme }) => css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0px solid transparent;
    `}
`