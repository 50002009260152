import styled, { css } from 'styled-components';
import { Modal } from 'react-bootstrap';

export const Container = styled(Modal)`
    ${({ theme, show, modalwidth, modalHeigth }) => css`
        z-index: 15000;    
        .modal-dialog {
            /* max-width: ${modalwidth}; */
            
            margin: 0 auto;
            /* padding: 0px 10px; */
            @media (min-width: 576px) {
                padding: 0;
            }
            @media (min-width: 990px) {
                width: ${modalwidth};
            }
        }
        .modal-content {
            background-color: transparent;
            border-radius: 24px;;
            outline: transparent;
            border: 1px solid rgba(0,0,0,0);
        }
    `}
`;

export const Header = styled(Modal.Header)`
    ${({ theme }) => css`
        display: flex;
        justify-content: flex-end;
        background-color: transparent;
        border-radius: 24px 24px 0 0; 
        color: #FFF;
        border-color: transparent;
    `}
`;