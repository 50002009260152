import P from 'prop-types';
import { hourMinutesString } from '../../services/dateUtils';
import { replaceAllSpecialCharacters } from '../../services/utils';
import * as Styled from './styles';
const LinesSchedulePaneLine = ({ 
        title, description, 
        startDate, endDate, 
        place, list = [],
        active
     }) => {
    const dateText = (startDate, endDate) => {
        const newStartDate = startDate.toLocaleTimeString().split(':').slice(0,2).join(":");

        return `${newStartDate} - ${hourMinutesString(endDate)} `
    }
    return (
        <Styled.Container active={active}>
            <Styled.Meta>
                {/* {startDate instanceof Date ? 
                <Styled.MetaItem>{dateText(startDate, endDate)}</Styled.MetaItem> : null} */}
                {place !== '' ? 
                <Styled.MetaItem as="span">{place}</Styled.MetaItem> : null }
            </Styled.Meta>
            <Styled.Info>
                <Styled.Title>{replaceAllSpecialCharacters(title)}</Styled.Title>
                {/* {list.length > 0 ?
                    <Styled.Paragraph>
                        {list.map((item, i) => `${i > 0 ? ' // ' : ''}${item.name}` )}
                    </Styled.Paragraph>
                : 
                null 
                } */}
            </Styled.Info>
            {/* <Styled.Photos>
                {list.length > 0 ?
                <Styled.List>
                    {list.map((item, i) => <Styled.ListItem key={i}><img src={item.imgUrl} /></Styled.ListItem>)}
                </Styled.List>
                : 
                null 
                }
            </Styled.Photos> */}
        </Styled.Container>
    );
};
LinesSchedulePaneLine.propTypes = {
    title: P.string.isRequired,
    description: P.string,
    place: P.node,
    startDate: P.instanceOf(Date),
    endDate: P.instanceOf(Date),
    list: P.arrayOf(P.object),
    active: P.bool
};
export default LinesSchedulePaneLine;