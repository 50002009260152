import styled, { css } from 'styled-components';
import { Modal } from 'react-bootstrap';

export const Container = styled(Modal)`
    ${({ theme, show, modalwidth }) => css`
        z-index: 15000;    
        .modal-dialog {
            max-width: ${modalwidth};
        }
        .modal-content {
            background-color: transparent;
            border-radius: 24px;;
            outline: transparent;
            border: 1px solid rgba(0,0,0,0);
        }
        /* max-width: ${modalwidth};
        position: fixed;
        display:  ${ show ? 'flex': 'none' };
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 24px;
        border: 2px solid ${theme.colors.mainColor}; */
    `}
`;

export const Header = styled(Modal.Header)`
    ${({ theme }) => css`
        display: flex;
        justify-content: flex-end;
        background-color: transparent;
        border-radius: 24px 24px 0 0; 
        color: #FFF;
        border-color: transparent;
    `}
`;