import P from 'prop-types';
import { useTheme } from 'styled-components';
import * as Styled from './styles';

import ModalVerticalFlexSize from '../../components/ModalVerticalFlexSize';
import ModalFlexSize from '../../components/ModalFlexSize';
import Video from '../../components/Video';
import VerticalVideo from '../../components/VerticalVideo';

import { useContext, useEffect, useState } from 'react';
import { VideoModalContext } from '../../context/VideoModalContext';

import iconClose from '../../assets/icon-close-2.png';

import TransparentContainer from '../../components/TransparentContainer';
import { formatYoutubeVideoUrl } from '../../services/videoFunctions';

import { videoModalOpen, videoModalTime } from '../../services/analyticsFn';

const VideoModal = () => {
    const theme = useTheme();
    const { open, setOpen, video } = useContext(VideoModalContext);
    

    useEffect(() => {
        const { title } = document;
        document.title = `${title} - ${video.title}`;
        return () => {
            document.title = title;
        }
    },[]);

    useEffect(() => {
        window.pauseSound();
        return () => window.resumeSound();
    },[])

    return ( <Styled.Container>
        {video.horizontal ? 
            <ModalFlexSize size={'lg'} show={open} onHideFn={() => setOpen(false)} headerText={''} centered={true}>
                <Styled.Content>
                    <Styled.CloseButton onClick={() => setOpen(false)}><img src={iconClose} /></Styled.CloseButton>
                    <Styled.Block flexBasis={'100%'}>
                        {/* <TransparentContainer bgColor={theme.colors.white} alpha={0.5}> */}
                         <Video videoUrl={video.link}/>
                        {/* </TransparentContainer> */}
                    </Styled.Block>
                </Styled.Content>
            </ModalFlexSize>
            : 
            <ModalVerticalFlexSize size={'lg'} show={open} onHideFn={() => setOpen(false)} headerText={''} centered={true}>
                    <Styled.Content>
                        <Styled.CloseButton onClick={() => setOpen(false)}><img src={iconClose} /></Styled.CloseButton>
                        <Styled.Block flexBasis={'100%'}>
                            {/* <TransparentContainer bgColor={theme.colors.white} alpha={0.5}> */}
                            <VerticalVideo videoUrl={video.link}/>
                            {/* </TransparentContainer> */}
                        </Styled.Block>
                    </Styled.Content>
                </ModalVerticalFlexSize>
            } 
                
            </Styled.Container>
    );
};
VideoModal.propTypes = {};
export default VideoModal;