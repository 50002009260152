import styled, { css } from 'styled-components';
export const Container = styled.li`
    ${({ theme, active }) => css`
        /* max-width: 350px; */
        /* width: 100%; */
        /* flex-grow: 1; */
        margin: 5px;
        font-family: ${theme.fonts.mainFont};
        font-size: 1.625rem;
        font-weight:700;
        text-transform: uppercase;
        list-style: none;
        line-height: initial;
        position: relative;
        flex-basis: 28%;
        @media (min-width: 576px) {
            max-width: 350px;
            flex-basis: 18%;
        }
    `}
`;
export const Link = styled.a.attrs({ dataToggle: 'tab', target:'_blank' })`
    ${({ theme, active }) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        text-decoration: none;
        text-align: center;
        min-height: 75px;
        width: 100%;
        /* padding: 6px 22px 6px; */
        border-radius: 15px;
        border: 2px solid  ${theme.colors.mainColor}};
        /* border-color: ${theme.colors.primaryGray} ${theme.colors.primaryGray} #fff;  */
        background-color: ${active ? theme.colors.mainColor : theme.colors.white};
        box-shadow: 0px 10px 50px 0px rgb(0 0 0 / 30%);
        color: ${active ? theme.colors.white : theme.colors.black };
        position: relative;
        transition: all 0.2s linear;
        @media (min-width: 576px) {
            padding: 6px 58px 8px;
        }
    `}
`;
export const Heading = styled.h5`
    ${({ theme, active }) => css`
        font-weight: 600;
        color: ${active ? theme.colors.white : theme.colors.mainDark};
        margin-bottom: 0;  
        @media (max-width: 992px){
            font-size: 16px;
            font-weight: 600;
            line-height: 1.2;
        }
    `}
`;
export const SmallText = styled.span`
    ${({ theme, active }) => css`
        font-size: 0.7rem;
        letter-spacing: -1px;
        font-weight: 400;
        display: block;
        text-transform: initial;
        @media (min-width: 576px) {
            font-size: 0.8rem;
        }
        @media (max-width: 850px){
            opacity: 1;
        }
    `}
`;