import P from 'prop-types';
import * as Styled from './styles';
import LinesScheduleNavbarItem from '../LinesScheduleNavbarItem';

const LinesScheduleNavbar = ({ data, selectedIndex, selectIndexFn }) => {

    return (
        <Styled.Container>
            {data.map((item, i) => <LinesScheduleNavbarItem key={i} {...item} active={i === selectedIndex} selectIndexFn={() => selectIndexFn(i)}/>)}
        </Styled.Container>
    );
};
LinesScheduleNavbar.propTypes = {
    data: P.arrayOf(P.object),
    selectedIndex: P.number,
    selectIndexFn: P.func
};
export default LinesScheduleNavbar;