import P from 'prop-types';
import * as Styled from './styles';
import ModalFixedSize from '../../components/ModalFixedSize';
import { useContext, useEffect } from 'react';
import { useTheme } from 'styled-components';

import iconClose from '../../assets/icon-close-2.png';
import TransparentContainer from '../../components/TransparentContainer';
import { LinkExternoModalContext } from '../../context/LinkExternoModalContext';
import { FolheteriaModalContext } from '../../context/FolheteriaModalContext';
import { AcessoSiteModalContext } from '../../context/AcessoSiteModalContext';
import { DiagonalTabContext } from '../../context/DiagonalTabContext';

const LinkExternoModal = () => {
    const theme = useTheme();
    const { open, setOpen, data } = useContext(LinkExternoModalContext);

    useEffect(() => {
        const { title } = document;
        document.title = title + ' - Saiba mais | ' + data.text ;
        if(data.href == "https://www.deere.com.br/pt/financiamento/banco-john-deere/"){
            window.windowRegisterCounter("clique_banco");
        }else if(data.href == "https://www.consorciojohndeere.com.br/"){
            window.windowRegisterCounter("clique_consorcio");
        }
        return () => {
            document.title = title;
        }
    },[]);

    return ( <Styled.Container>
                <ModalFixedSize size={'md'} show={open} onHideFn={() => setOpen(false)} >
                    <Styled.Content>
                        <Styled.CloseButton onClick={() => setOpen(false)}><img src={iconClose} /></Styled.CloseButton>
                        <Styled.Block flexBasis={'100%'}>
                            <TransparentContainer bgColor={theme.colors.white} alpha={1}>
                                <Styled.SecondaryHeading>Saiba mais em nosso site</Styled.SecondaryHeading>
                                <Styled.ButtonsContainer>
                                    <Styled.Button href={data.href} >Acesse</Styled.Button>
                                </Styled.ButtonsContainer>
                            </TransparentContainer>
                        </Styled.Block>
                    </Styled.Content>
                </ModalFixedSize>
            </Styled.Container>
    );
};
LinkExternoModal.propTypes = {};
export default LinkExternoModal;