import styled, { css } from 'styled-components';
import locationIcon from '../../../assets/icon-location.png';
import phoneIcon from '../../../assets/icon-phone-2.png';
import whatsappIcon from '../../../assets/icon-whatsapp-2.png';
export const Container = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        width: 374px;
        border: 1px solid #E5E6E6;
        padding: 25px 50px;
        gap: 20px;  
    `}
`;
export const Block = styled.div`
    ${({ theme }) => css`
        
    `}
`;
export const Name = styled.h4`
    ${({ theme }) => css`
        font-size: 12px;
        font-weight: 700;
        margin: 0;
    `}
`;
export const Phone = styled.a.attrs({target: 'blank'})`
    ${({ theme }) => css`
        cursor: pointer;
        font-size: 10px;
        text-decoration: none;
        color: ${theme.colors.gray};
        :hover{
            color: ${theme.colors.mainColor};
        }
    `}
`;
export const Whatsapp = styled(Phone)`
    ${({ theme }) => css`
    `}
`;
export const BasicIcon = styled.img`
    ${({ theme }) => css`
        margin-right: 10px;
    `}
`
export const WhatsappIcon = styled(BasicIcon).attrs({ src: whatsappIcon })``
export const LocationIcon = styled(BasicIcon).attrs({ src: locationIcon })``
export const PhoneIcon = styled(BasicIcon).attrs({ src: phoneIcon })``