import { createContext, useState } from 'react';

export const LinkExternoModalContext = createContext();

export const LinkExternoModalContextProvider = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({
        text: '',
        href: '#'
    });
    const value = { open, setOpen, data, setData};

    return <LinkExternoModalContext.Provider value={value}>{children}</LinkExternoModalContext.Provider>
}