import { useEffect, useState } from 'react';
import P from 'prop-types';
import * as Styled from './styles';
const ModalFlexSize = ({ headerText, children, show, onHideFn }) => {

    const [modalWidth, setModalWidth] = useState('0px');

    useEffect(() => {
        const aspectRatio = window.innerWidth / window.innerHeight;
        const maxWidth = window.innerWidth - 20;
        const maxHeight = window.innerHeight - 110;

        if(aspectRatio > 1){
            setModalWidth(`${maxHeight * 16 / 9 }px`);
        } else {
            setModalWidth(`${maxWidth}px`);
        }
    },[])

    return (
        <Styled.Container show={show} modalwidth={modalWidth}  onHide={onHideFn} centered>
            {/* {(headerText !== '' || headerText !== undefined || headerText !== null) ? <Styled.Header closeButton >{headerText}</Styled.Header> : null } */}
            {(headerText === '' || headerText === undefined || headerText === null) ? null : <Styled.Header closeButton >{headerText}</Styled.Header> }
            { children }
        </Styled.Container>
    );
};
ModalFlexSize.propTypes = {
    headerText: P.string,
    children: P.node.isRequired,
};
export default ModalFlexSize;