import styled, { css } from 'styled-components';
import { Container as TransparentContainer } from '../../components/TransparentContainer/styles';
export const Container = styled.div`
    ${({ theme }) => css`
        
    `}
`;
export const Content = styled.div`
    ${({ theme }) => css`
        /* padding: ${theme.spacings.gutter}px; */
        position: relative;
        display: flex;
        width: 100%;
        border-radius: 10px;
        border: 1px solid rgba(0,0,0,0.1);
        @media (max-width: 768px){
            flex-direction: column;
        }
    `}
`;
export const Block = styled.div`
    ${({ theme, flexBasis }) => css`
        /* padding: ${theme.spacings.gutter}px; */
        display: flex;
        flex-basis: ${flexBasis};
        ${TransparentContainer}{
            padding: ${theme.spacings.gutter}px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            div{
                display: flex;
                flex-direction: column;
            }
        }
    `}
`;
export const CloseButton = styled.button`
    ${({ theme }) => css`
        position: absolute;
        top: 10px;
        right: 5px;
        background: ${theme.colors.white};
        border: 1px solid transparent;
        z-index: 1500;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        border-radius: 4px;
        padding: 5px 10px;

        @media (min-width: 576px) {
            top: -10px;
            right: -10px;
        }
    `}
`;

export const MainHeading = styled.h1`
    ${({ theme }) => css`
        color: ${theme.colors.secondaryColor};
        font-weight: 700;
        font-size: 26px;
        text-transform: uppercase;
    `}
`;

export const SecondaryHeading = styled.h4`
    ${({ theme }) => css`
        color: ${theme.colors.terciaryColor};
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
        text-align: left;
    `}
`;
export const Paragraph = styled.p`
    ${({ theme }) => css`
        color: ${theme.colors.white};
        font-weight: 300;
        font-size: 13px;
    `}
`;
