import { useState } from 'react';
import P from 'prop-types';
import LinesScheduleNavbar from '../LinesScheduleNavbar';
import LinesSchedulePanes from '../LinesSchedulePanes';
import * as Styled from './styles';
const LinesSchedule = ({ data }) => {

    const [selectedIndex, setSelectedIndex] = useState(0);

    const weekDays =  ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];

    const setIndex = (index) => {
        setSelectedIndex(index);
    }

    const setNavbarText = (data) => {
        return data.map((item, i) => {
            const date = new Date(item.children[0].key.split('.')[0]);
            return {
                    mainText: `${date.getDate() + 1 }/${date.getMonth() + 1 }`,
                    smallText: weekDays[date.getDay() + 1]
                }
            }
        )
    }

    return (
        <Styled.Container>
            <LinesScheduleNavbar data={setNavbarText(data)} selectedIndex={selectedIndex} selectIndexFn={setIndex}/>
            <LinesSchedulePanes data={data} selectedIndex={selectedIndex} />
        </Styled.Container>
    );
};
LinesSchedule.propTypes = {
    data: P.arrayOf(P.object),
};
export default LinesSchedule;